import $ from 'jquery';
import '@/scripts/helpers/polyfills';
import lazyframe from 'lazyframe';
import { CLASSES } from '@/scripts/helpers/constants';
import 'slick-carousel/slick/slick';
import { bindJQuery } from '@/scripts/helpers/library';
import { accordion } from '../modules/containers/accordion/index';
import { detectBrowser } from '@/scripts/helpers/detectBrowser';

const { IS_OPEN } = CLASSES;

bindJQuery();

const showMore = () => {
  if ($('.js-show-more').length) {
    $('.js-show-more').on('click', function () {
      $(this).text($(this).text() === 'Show more' ? 'Show less' : 'Show more');
      $(this).parent().toggleClass(IS_OPEN);
      const hiddenContent = $(this).parent().prev();
      const innerHeight = hiddenContent.find('.section-content__inner').innerHeight();
      hiddenContent.toggleClass(IS_OPEN);

      if (hiddenContent.hasClass(IS_OPEN)) {
        hiddenContent.css({ height: innerHeight });
      } else {
        hiddenContent.removeAttr('style');
      }
    });
  }
};

const initSlider = (selector, arrowPrev, arrowNext) => {
  if (selector) {
    selector.not('.slick-initialized').slick({
      responsive: [
        {
          breakpoint: 99999,
          settings: 'unslick',
        },
        {
          breakpoint: 1023,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: true,
            prevArrow: arrowPrev,
            nextArrow: arrowNext,
          },
        },
        {
          breakpoint: 768,
          settings: 'unslick',
        },
      ],
    });
  }
};

const initRaiting = (selector) => {
  const ratingStars = selector.find('.raiting__star');
  
  const handleHoverStar = function () {
    const index = $(this).index();
    for (let i = 0; i <= index; i += 1) {
      $(ratingStars[i]).addClass('is-active');
    }
  };

  const handleBlurStar = function () {
    ratingStars.removeClass('is-active');
  };

  const handleClickStar = function () {
    const index = $(this).index();
    ratingStars.removeClass('is-half-filled is-filled').addClass('star--empty');

    for (let i = 0; i <= index; i += 1) {
      $(ratingStars[i]).removeClass('star--empty').addClass('is-filled');
    }

    localStorage.setItem('rating-star', index + 1);
  };

  ratingStars.hover(handleHoverStar, handleBlurStar);
  ratingStars.click(handleClickStar);
};

$(document).ready(() => {
  accordion();
  showMore();
  detectBrowser();
  initSlider($('.js-ultimate-slider'), $('.slider-arrow--prev-item'), $('.slider-arrow--next-item'));
  initSlider($('.js-related-slider'), $('.slider-arrow--prev-related'), $('.slider-arrow--next-related'));
  initRaiting($('.raiting__stars'));

  $(window).on( "resize", function() {
    initSlider($('.js-ultimate-slider'), $('.slider-arrow--prev-item'), $('.slider-arrow--next-item'));
    initSlider($('.js-related-slider'), $('.slider-arrow--prev-related'), $('.slider-arrow--next-related'));
  });

  const elements = $('.video-player__lazyframe');

  lazyframe(elements, {
    onAppend: iframe => {
      $(iframe).attr('allow', 'autoplay');
    },
  });

  const $slider = $('.js-slider');

  $slider.on('init', () => {
    $('.carousel').addClass('is-visible');
  });

  $slider.slick({
    dots: false,
    arrows: true,
    infinite: false,
    fade: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: $('.slider-arrow--prev'),
    nextArrow: $('.slider-arrow--next'),
  });
});
